import React from 'react'

export const Logo = ({ color = '#d4d8d2', ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 80 62"
        height="62"
        width="80"
        version="1.1"
        {...props}
    >
        <defs id="defs819">
            <clipPath id="clipPath831" clipPathUnits="userSpaceOnUse">
                <path id="path829" d="M 0,2808 H 3744 V 0 H 0 Z" />
            </clipPath>
        </defs>
        <g transform="matrix(1.3333333,0,0,-1.3333333,0,62)" id="g823">
            <g transform="matrix(0.06147723,0,0,0.06147723,-85.112161,-63.079397)" id="g825">
                <g clip-path="url(#clipPath831)" id="g827">
                    <g transform="translate(1534.6538,1670.2822)" id="g833">
                        <path
                            id="path835"
                            style={{
                                fill: color,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none',
                            }}
                            d="m 0,0 v 80.291 h 130.845 v 25.552 h 139.351 v -252.908 h -139.39 c -0.437,4.247 -0.835,8.101 -1.298,12.602 H 74.249 V -277.406 H -138.004 V 0 Z"
                        />
                    </g>
                    <g transform="translate(2209.2461,1138.0117)" id="g837">
                        <path
                            id="path839"
                            style={{
                                fill: color,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none',
                            }}
                            d="m 0,0 v -80.121 h -130.849 v -25.516 h -139.318 v 252.99 h 139.432 c 0.469,-4.393 0.881,-8.249 1.357,-12.69 h 55.269 v 143.061 h 212.097 c 0.283,-4.367 0.714,-7.92 0.715,-11.473 0.039,-84.922 -0.149,-169.846 0.272,-254.767 0.05,-10.246 -3.324,-11.848 -12.65,-11.723 C 84.847,0.312 43.355,0 0,0"
                        />
                    </g>
                    <g transform="translate(2209.4219,1671.4624)" id="g841">
                        <path
                            id="path843"
                            style={{
                                fill: color,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none',
                            }}
                            d="M 0,0 H 137.893 V -187.166 H -73.85 v 64.36 h -55.348 c -0.632,-3.702 -1.229,-7.199 -1.955,-11.454 H -269.824 V 96.453 h 138.064 c 0.347,-7.478 0.682,-14.682 1.081,-23.276 H 0 Z"
                        />
                    </g>
                    <g transform="translate(1665.6045,1040.623)" id="g845">
                        <path
                            id="path847"
                            style={{
                                fill: color,
                                fillOpacity: 1,
                                fillRule: 'nonzero',
                                stroke: 'none',
                            }}
                            d="m 0,0 v 23.261 h -131.073 v 72.812 h -137.88 v 187.545 h 211.745 v -64.359 h 55.351 c 0.626,3.706 1.215,7.2 1.935,11.457 H 138.765 V 0 Z"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)
