const soundTracksDark = [
    {
        id: 'disco-track-29178223',
        name: 'disco-track-29178223',
        src: 'https://tent7.disco.ac/e/t/29178223',
        download: 'false',
        hash: 'KHm0afJmSNGwsqt-WFpFkOpFoik:URy8iw1x',
        artwork: 'false',
        theme: 'dark',
        color: '#979797',
    },

    {
        id: 'disco-track-29178243',
        name: 'disco-track-29178243',
        src: 'https://tent7.disco.ac/e/t/29178243',
        hash: 'qdBJJEnnzLeizeo6MBnMJIhbAd8:tZ93Jcz5',
        theme: 'dark',
        color: '#979797',
    },
]

const soundTracksWhite = [
    {
        id: 'disco-track-29178227',
        name: 'disco-track-29178227',
        class: 'disco-embed',
        src: 'https://tent7.disco.ac/e/t/29178227',
        hash: '-I94Q2N5vVxvKMN9_edJ26mGIZs:8UIGzoKp',
        theme: 'white',
        color: '#222222',
    },
    {
        id: 'disco-track-29178232',
        name: 'disco-track-29178232',
        class: 'disco-embed',
        src: 'https://tent7.disco.ac/e/t/29178232',
        hash: 'y5fAdHIf8JgRXPK6_wXl1x0LJs0:e5vEesZl',
        theme: 'white',
        color: '#222222',
    },
]

export { soundTracksDark, soundTracksWhite }
