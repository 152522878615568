import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Head from '../components/Head'

import { AlbumPage } from '../components/AlbumPage'
import { Navigation } from '../components/Navigation'
import { soundTracksDark, soundTracksWhite } from '../data/soundtracks.js'

import '../global.scss'
import { useState } from 'react'
import { useEffect } from 'react'

const Main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    padding: 0;
`

const AlbumPages = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    min-height: 100vh;
    @media (min-width: 768px) {
        flex-direction: row;
    }
`

const IndexPage = ({ data }) => {
    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        setInitialized(true)
    }, [])
    return (
        <Main style={{ opacity: initialized ? 1 : 0, transition: 'opacity 0.3s ease' }}>
            <Head />
            <Navigation />
            <AlbumPages>
                <AlbumPage
                    variant="dark"
                    soundTracks={soundTracksDark}
                    image={data.albumImageDark}
                    label="Part 1"
                />
                <AlbumPage
                    variant="white"
                    soundTracks={soundTracksWhite}
                    image={data.albumImageWhite}
                    label="Part 2"
                />
            </AlbumPages>
        </Main>
    )
}
export default IndexPage

export const pageQuery = graphql`
    query HomeQuery {
        albumImageWhite: file(relativePath: { eq: "image2.png" }) {
            childImageSharp {
                fluid(maxWidth: 1300) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        albumImageDark: file(relativePath: { eq: "image1.png" }) {
            childImageSharp {
                fluid(maxWidth: 1300) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
