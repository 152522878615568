import React from 'react'
import { keys } from 'lodash-es'
import styled from 'styled-components'

const Iframe = styled.iframe`
    max-width: 100%;
    margin-bottom: 0.2rem;
    overflow: hidden;

    background-color: ${({ theme }) => (theme == 'dark' ? '#222222' : '#eeeeee')};
`

export const DiscoPlayer = ({ id, name, src, hash, theme, color, ...props }) => {
    const parameters = {
        download: false,
        s: encodeURIComponent(hash),
        artwork: false,
        theme,
        color: encodeURIComponent(color),
        by: '%20',
    }

    const parametersString = keys(parameters)
        .map((k) => `${k}=${parameters[k]}`)
        .join('&')

    return (
        <Iframe
            {...props}
            id={id}
            name={name}
            allowFullScreen
            frameBorder="0"
            theme={theme}
            src={`${src}?${parametersString}`}
            width="480"
            height="208"
            scrolling="no"
        />
    )
}
