import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Image from './Image'
import { Logo } from './Logo'

const NavigationLogo = styled(Logo)`
    @media (max-width: 767px) {
        width: 50px;
    }
`

const Root = styled.nav`
    position: absolute;

    top: 10px;
    right: 20px;
    left: 20px;

    @media (min-width: 767px) {
        top: 40px;
        left: 60px;
        right: 60px;
    }
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const NavLink = styled.a`
    color: #d4d8d2;
    font-family: 'Druk Wide Medium', sans-serif;
    text-decoration: none;

    font-size: 16px;
    @media (min-width: 768px) {
        font-size: 26px;

        &:hover {
            text-decoration: underline;
        }
    }
`

const Navigation = ({ ...props }) => {
    return (
        <Root {...props}>
            <NavigationLogo />
            <NavLink href="mailto:cannondivision@gmail.com">CONTACT</NavLink>
        </Root>
    )
}

export { Navigation }
