import React from 'react'
import styled, { css } from 'styled-components'
import { DiscoPlayer } from './DiscoPlayer.jsx'
import reset from '../utils/styles/reset'
import Image from './Image.js'

const labelHeight = 5

const SoundTrack = styled.li`
    ${reset.li}

    transform: scale(0.6);
    margin: -80px; 0;

    @media (min-width: 375px) {
      transform: scale(0.7);
      margin: -60px; 0;
    }

    @media(min-width: 768px) {
      transform: scale(0.8);
      margin: -40px; 0;
    }

    @media(min-width: 1600px) {
      transform: scale(1);
      margin: 0;
    }
`

const SoundTracks = styled.ul`
    ${reset.ul}
`

const Content = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 20px;
    right: 20px;

    @media (min-width: 768px) {
        padding-top: 20px;
        left: 40px;
        right: 40px;
    }

    box-sizing: border-box;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Root = styled.div`
    position: relative;
    box-sizing: border-box;

    width: 100%;
    min-height: ${100 - labelHeight}vh;

    @media (min-width: 768px) {
        width: 50%;
        min-height: 100vh;
    }

    ${({ variant }) =>
        variant == 'dark'
            ? css`
                  background-color: black;
                  color: white;
              `
            : css`
                  background-color: white;
                  color: black;
              `}
`

const AlbumImage = styled(Image)`
    width: 100%;
    max-width: 100px;

    margin-bottom: 60px;

    @media (min-width: 375px) {
        margin-bottom: 80px;
        max-width: 120px;
    }

    @media (min-width: 768px) {
        margin-bottom: ${(80 / 1920) * 100}vw;

        width: ${(240 / 1920) * 100}vw;
        max-width: 240px;
    }
    @media (min-width: 1920px) {
        margin-bottom: 80px;
    }
`

const AlbumLabel = styled.caption`
    font-family: 'Druk Wide Medium', sans-serif;
    position: absolute;
    min-height: ${labelHeight}vh;
    left: 0;
    right: 0;
    ${({ position }) => `${position}: 0;`}
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    font-size: 12px;
    letter-spacing: 1.2px;
    text-transform: uppercase;

    @media (min-width: 767px) {
        display: none;
    }
`

export const AlbumPage = ({ variant, soundTracks, image, children, label, ...props }) => {
    return (
        <Root variant={variant} {...props}>
            <AlbumLabel position={variant == 'dark' ? 'bottom' : 'top'}>{label}</AlbumLabel>
            <Content>
                <AlbumImage {...image} />
                <SoundTracks>
                    {soundTracks.map((soundTrack, key) => (
                        <SoundTrack key={key}>
                            <DiscoPlayer {...soundTrack} />
                        </SoundTrack>
                    ))}
                </SoundTracks>
            </Content>
            {children}
        </Root>
    )
}
