import React from "react"
import styled from "styled-components"
import GatsbyImage from "gatsby-image"

const PlaceholderImage = styled.div`
  width: 400px;
  height: 400px;
  background-color: #999;
`

const Image = ({ childImageSharp, ...props }) =>
  childImageSharp ? (
    <GatsbyImage {...childImageSharp} {...props} />
  ) : (
    <PlaceholderImage {...props} />
  )

export default Image
