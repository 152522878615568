import { css } from "styled-components"

const reset = css`
  margin: 0;
  padding: 0;
`

const li = css`
  ${reset}
`

const ul = css`
  ${reset}
  list-style: none;
`

const button = css`
  ${reset}
  &::focus {
    outline: none;
  }

  &::-moz-focus-inner {
    border: 0;
  }

  border: none;
  background: transparent;
  overflow: visible;

  -webkit-appearance: none;
  appearance: none;

  cursor: pointer;
`

const input = css`
  ${reset}
  background-color: transparent;
  border-color: transparent;
  border: none;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    outline: none;
  }
`

export default {
  ...[
    "div",
    "span",
    "applet",
    "object",
    "iframe",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "blockquote",
    "pre",
    "a",
    "abbr",
    "acronym",
    "address",
    "big",
    "cite",
    "code",
    "del",
    "dfn",
    "em",
    "img",
    "ins",
    "kbd",
    "q",
    "s",
    "samp",
    "small",
    "strike",
    "strong",
    "sub",
    "sup",
    "tt",
    "var",
    "b",
    "u",
    "i",
    "center",
    "dl",
    "dt",
    "dd",
    "fieldset",
    "form",
    "label",
    "legend",
    "table",
    "caption",
    "tbody",
    "tfoot",
    "thead",
    "tr",
    "th",
    "td",
    "article",
    "aside",
    "canvas",
    "details",
    "embed",
    "figure",
    "figcaption",
    "footer",
    "header",
    "hgroup",
    "menu",
    "nav",
    "output",
    "ruby",
    "section",
    "summary",
    "time",
    "mark",
    "audio",
    "video",
  ].reduce((all, tag) => ({ ...all, [tag]: reset }), {}),

  button,
  li,
  ul,
  ol: ul,

  input,
}
